.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.login-form {
  border-radius: 45px;
  color: #282c34;
  padding: 10px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #e8ecf4;
  width: 50%;
  left: 25%;
  top: 20px;
  border: 3px solid #080c14;
}

.login-form input[type=text],
.login-form input[type=password] {
  background-color: #fff;
  margin-top: 2px;
  height: 25px;
  width: 120%;
  border-radius: 5px;
  border: 2px solid rgba(40, 44, 52, 0.5);
  font-size: 20px;
}

input[type=text]:focus,
.login-form input[type=password]:focus {
  border: 2px solid #282c34;
}

.form-hr {
  color: #282c34;
  border: 1px dashed #080c14;
  width: 80%;
}

.form-p {
  margin: 0;
}

.form-signup {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-submit {
  background-color: #282c34;
  color: #e8ecf4;
  padding: 12px 24px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 15px;
  font-weight: 700;
  transition: all 0.3s ease;
}

.form-submit:hover {
  background-color: #e8ecf4;
  border: 2px solid #282c34;
  color: #282c34;
  transition: all 0.3s ease;
}

.form-sect {
  margin: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-weight: 500;
  color: rgba(40, 44, 52, 0.95);
}

.dedication {
  padding: 5px;
  text-align: center;
  text-decoration: underline;
}

.form-err {
  font-weight: 500;
  color: rgb(255, 0, 0);
}

.app-container {
  padding-top: 25px;
  padding-left: 25px;
  background-image: linear-gradient(to bottom,  #282c34, #080c14, #282c34, #080c14, #282c34 );
  color: #e8ecf4;
  min-width: 100%;
  position: absolute;
  z-index: -5;
} 

.main-content {
  margin-top: 70px;
  margin-bottom: 20px;
  position: relative;
  left: -25px;
}

.img-container {
  color: #282c34;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.img-card {
  background: #e8ecf4;
  border-radius: 15px;
  margin: 5px;
}

.expand-thing {
  margin: 0;
  padding: 0;
  overflow: hidden!important;
}

.views-table {
  border: 2px solid #080c14;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

.views-table th,
.views-table td {
  background-color: #e8ecf4;
  padding: 4px 8px;
}

.views-table th {
  border-bottom: 2px solid black;
}

.eye-icon {
  position: relative;
  top: 2px;
}

.img-info {
  text-align: center;
  cursor: pointer;
}

.big-img {
  position: absolute;
  left: 2.5%;
  width: 95%;
  max-width: 95%;
  top: 25%;
}

.big-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  background: rgba(255, 255, 255, 0.9);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-tag-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.close {
  position: absolute;
  text-align: center;
  top: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: right;
  cursor: pointer;
  color: white;
  mix-blend-mode: difference;
  z-index: 9999999999999999999999999999 !important; 
  border: 3px solid #fff;
  border-radius: 20px;
}

.title:hover {
  text-decoration: 2px underline;
}

.img {
  align-content: center;
  height: 150px;
  margin: 10px;
  margin-bottom: 0;
  border: 3px solid #282c34;
  border-radius: 7px;
}

.img-controls {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 20px;
}

.flag {
  position: relative;
  top: 5px;
  left: 8px;
}

.control {
  font-weight: 700;
  padding: 7px 12px;
  margin: 0 1px;
  background-color: #e8ecf4;
  border: 3px solid #282c34;
  border-radius: 15px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.control:hover {
  background: #282c34;
  color: #e8ecf4;
  transition: all 0.2s ease;
}

.btn-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.load-btn {
  margin-top: 5px;
  background-color: #e8ecf4;
  border: none;
  border-radius: 5px;
  color: #282c34;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.load-btn:disabled,
.load-btn[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  opacity: 0.6;
  cursor: not-allowed;
}

.arrow-btn {
  padding-right: 5px;
  position: relative;
  top: -2px;
  z-index: 0;
}

.sort-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  margin: 0;
  background: #282c34;
  padding: 0;
  border: 2px solid #e8ecf4;
  border-radius: 15px;
  cursor: pointer;
  transition: all 0.2s ease;
  z-index: 1;
}

.sort-open .sort-btn {
  margin-top: 5px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.sort-btn:hover {
  background: #e8ecf4;
  color: #282c34;
  transition: all 0.2s ease;
  z-index: 1;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.load-btn:hover:not(.load-btn[disabled]) {
  transform: scale(1.1);
  transition: all 0.2s ease-in-out;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.nav-icon {
  position: relative;
  font-size: 20px;
  top: 4px;
}

.settings-nav {
  position: absolute;
  right: 25px !important;
}

.sort-content {
  opacity: 0;
  position: absolute;
  transition: all 0.5s ease;
}

.sort-open .sort-content {
  opacity: 1;
  position: relative;
  top: -5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border: 2px solid #e8ecf4;
  border-radius: 15px;
  margin: 0;
  padding: 0 8px;
  background-color: #282c34;
  color: #e8ecf4;
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  transition: all 0.5s ease;
}

.sort-option:hover {
  background-color: #e8ecf4;
  color: #282c34;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.5s ease;
}

.sort-option {
  margin: 2px;
  padding: 5px;
  width: 90%;
  transition: all 0.5s ease;
}

.topnav {
  position: fixed;
  width: calc(100% - 50px);
  background-color: #080c14;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 2px solid #e8ecf4;
  border-radius: 15px;
  z-index: 1;
}

/* Style the links inside the navigation bar */
.topnav a {
  color: #e8ecf4 !important;
  text-align: center;
  text-decoration: none !important;
  padding: 20px;
  padding-top: 17px;
  font-size: 16px;
  transition: all 0.4s ease;
}

/* Change the color of links on hover */
.topnav a:hover {
  transform: scale(1.15);
  transition: all 0.4s ease;
}

.devices-container:hover .sort-one {
  opacity: 1;
  position: relative;
  font-weight: 600;
}

.sort-one {
  position: absolute;
  opacity: 0;
}

._loading_overlay_content {
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  top: 50vmin;
}

.make-big {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  z-index: 2;
}

@media (max-width: 720px) {
  .nav-text {
    opacity: 0;
    position: absolute;
  }

  .form-p {
    font-size: 11px;
    font-weight: 700;
  }

  .login-form input[type=text],
  .login-form input[type=password] {
    width: 80%;
  }

}

.pin-container {
  position: relative;
  width: 50%;
  height: 250px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border: 2px solid #e8ecf4;
  border-radius: 5px;
  padding: 5px;
}

.pin-container input[type=text] {
  width: 101px;
  height: 15%;
  color: #282c34;
  font-size: 30px;
  font-weight: 700;
  background: #e8ecf4;
  border: 3px solid #080c14;
  border-radius: 5px;
  padding: 5px;
  text-align: center;
}

.pin-h3 {
  margin: 0;
}

.pin-p {
  font-size: 13px;
  text-align: center;
}

.pin-btn {
  width: 125px;
  height: 30px;
  font-weight: 600;
  font-size: 15px;
  background-color: #e8ecf4;
  border: 2px solid #080c14;
  border-radius: 5px;
  cursor: pointer;
}

.edit-h3 {
  position: relative;
  text-align: center;
  margin: 50px;
  top: 25px;
  text-decoration: underline;
}
.edit-user {
  background-color: rgba(8, 12, 20, 0.8);
  position: relative;
  left: 12.5%;
  width: 75%;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: space-evenly;
  flex-direction: column;
  border: 3px solid #e8ecf4;
  text-align: center;
  border-radius: 40px;
}

.settings-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

.edit-hr {
  margin-top: 20px;
  width: 80%;
  border-top: 1px dashed #e8ecf4;
}

.edit-h4 {
  margin: 6px;
}

.addEmailSpan {
  width: 100%;
  padding-bottom: 10px;
}

.addEmailSpanAdd {
  position: absolute;
  font-size: 25px;
  height: 39px;
  margin-left: 5px;
  cursor: pointer;
}

.addEmailSpanAdd:hover {
  color: white;
}

.edit-input {
  width: 50%;
  height: 25px;
  border: none;
  border-radius: 7px;
  margin: 5px;
  padding: 0 5px;
  font-size: 20px;
  font-weight: 550;
  box-shadow: 2px 3px rgba(97, 218, 251, 0.3);
  padding-bottom: 4px;
}

.edit-input:focus {
  outline: none !important;
}

.edit-pass {
  font-size: 35px;
}

.edit-input::placeholder {
  position: relative;
  font-size: 15px;
  top: -6px;
  font-weight: 550;
}

.table-section {
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.table {
  width: 75% !important;
  border: 2px solid #e8ecf4;
  color: #e8ecf4;
}

tr:nth-of-type(odd) {
  background-color: #080c14 !important;
}

thead .first-tr {
  background: #e8ecf4 !important;
  color: #181c34;
  font-weight: 900;
  font-size: 18px;
}

tr:nth-of-type(even) {
  background-color: #181c24 !important;
}

.anchor:hover {
  cursor: pointer;
  text-decoration: underline;
  color: lightblue;
}

.save-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 10px;
  width: 88%;
}

.save-btn {
  position: relative;
  width: 150px;
  height: 40px;
  font-weight: 600;
  font-size: 18px;
  margin: 10px;
  background-color: #e8ecf4;
  border: 2px solid #080c14;
  border-radius: 5px;
  cursor: pointer;
}

.logout-btn {
  position: relative;
  width: 50px;
  height: 40px;
  font-weight: 600;
  font-size: 20px;
  margin: 10px;
  background-color: #e8ecf4;
  border: 2px solid #080c14;
  border-radius: 5px;
  cursor: pointer;
}

.save-hr {
  margin-top: 20px;
  width: 75%;
  border-top: 1px solid #e8ecf4;
}

.pin-input {
  width: 85px;
  text-align: center;
}

.req-input {
  box-shadow: none;
}

.close-aro {
  font-weight: 900;
  font-size: 50px;
  padding: 0;
  margin: 0;
}

.pictures-view-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around ;
  align-items:center;
}

.sort-container {
  position: fixed;
  top: 100px;
  right: 25px;
}

.minify-section {
  position: relative;
  margin: 15px;
  top: 5px;
  font-weight: 900;
  cursor: pointer;
}

.big-image {
  width: 100%;
  max-height: 100%;
}

@font-face {
  font-family: 'home-font';
  src: url('../public/home-font.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
} 

.bsd {
  text-decoration: underline;
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 600;
}

.home-h1 {
  margin: 0;
  padding-top: 5px;
  font-weight: 500;
  font-size: 45px;
}

.home-h3 {
  font-weight: 100;
  margin: 0;
}

.home-hr {
  width: 50%;
  opacity: 0.8;
}

.homePage {
  font-family: "home-font";
  color: #d8dce4;
  display: grid;
  place-items: center;
}

.homeChild {
  position: relative;
  width: calc(100% + 100px);
  height: calc(100% - 70px);
  left: -25px;
  right: -25px;
  border-radius: 10px;
}

.homeHead {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@font-face {
  font-family: 'home-font2';
  src: url('../public/home-font2.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
} 

.homeBody {
  font-family: "home-font2";
  margin-left: 15%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.cameraImg {
  opacity: 0.4;
  margin: 0;
  padding: 0;
}

.home-body-h2 {
  margin: 0;
}

.home-body-p {
  margin: 0;
  padding: 0 20px 8px 0;
  border-bottom: 1px solid #e8ecf4;
}

.home-body-h3 {
  margin: 0;
  margin-top: 8px;
}

.signup-btn {
  position: relative;
  right: -68%;
  bottom: 25px;
  padding: 12px 45px;
  padding-top: 10px;
  background-color: #e8ecf4;
  color: #282c34;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  transition: all 0.3s ease;
  text-decoration: none;
}

.signup-btn:hover {
  background-color: #282c34;
  border: 2px solid #e8ecf4;
  color:#e8ecf4 ;
  transition: all 0.5s ease;
}

.signup-right-caret {
  position: relative;
  font-size: 20px;
  top: 5px;
}

.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.plan-card-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #080c14;
}

.plan-card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 350px;
  padding: 10px;
  margin: 10px;
  border: 2px solid #080c14;
  border-radius: 15px;
  background-color: #e8ecf4;
}

.signup-ul {
  display: flex;
  flex-direction: column;
  width: 85%;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 0;
}

.signup-ul li::marker {
  content: "\2022\00A0";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #080c14; /* Change the color */

}

.signup-ul li {
  margin: 5px;
  font-weight: 600;
}

.signup-h2 {
  text-decoration: underline;
  margin-bottom: 5px;
}

.signup-devices {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}

.signup-device-btn{ 
  border: 2px solid #080c14;
  background-color: #d8dce4;
  font-size: 20px;
  font-weight: 700;
  border-radius: 50%;
  margin: 0 5px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.minus-device-btn {
  letter-spacing: -2px;
  position: relative;
  left: -1px;
  top: -1px;
}

.plus-device-btn {
  letter-spacing: -2px;
  position: relative;
  left: -1px;
  top: 1.5px;
}

.device-number {
  font-size: 21px;
  font-weight: 700;
  padding: 5px;
  position: relative;
  bottom: -2px;
}

.signup-hr {
  margin: 0;
  padding: 0;
  width: 90%;
  border-bottom: 1px solid #080c14;
}

.signup-card-btn {
  margin: 10px 0;
  padding: 12px 45px;
  padding-top: 10px;
  background-color: #e8ecf4;
  color: #282c34;
  border-radius: 5px;
  border: 2px solid #080c14;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  transition: all 0.3s ease;
  text-decoration: none;
}

.signup-card-btn:hover {
  background-color: #282c34;
  border: 2px solid #e8ecf4;
  color:#e8ecf4 ;
  transition: all 0.5s ease;
}

.signup-h1 {
  margin: 0;
  margin-bottom: -40px;
  padding-top: 5px;
  font-weight: 500;
  font-size: 45px;
  text-align: center;
  text-decoration: underline;
}

#canvas {
  position: fixed;
  background-image: linear-gradient(to bottom,  #282c34, #080c14, #282c34, #080c14, #282c34 );
  width: 110%;
  height: 110%;
  color: #e8ecf4;
  margin-left: -25px;
  margin-top: -50px;
  z-index: -1;
}

body, html {
  overflow-y: auto;
  overflow-x: hidden;
}

.popup-container {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
}

.popup-h5 {
  align-self: center;
  font-size: 1.25rem;
  margin: 0;
  padding: 0;
  margin-bottom: 4px;
}

.popup-p {
  margin: 0;
  padding: 0;
}

.one-popup {
  justify-self: flex-start;
  align-self: center;
}